import { createBrowserRouter } from 'react-router-dom';
import ExchangeRates from '../components/ExchangeRates';
import LoginPage from '../components/admin/LoginPage';
import NewsEditPage from '../components/admin/NewsEditPage';
import NewsHome from '../components/news/NewsHome';
import NewsArticle from '../components/news/NewsArticle';
import Navigation from '../components/Navigation';

export const router = createBrowserRouter([
  {
    path: '/',
    element: (
    <>
    <Navigation />
    <ExchangeRates />
  </>)
  },
  {
    path: '/admin',
    element: <LoginPage />,
  },
  {
    // TODO: 需要檢查權限
    path: '/admin/news/edit',
    element: <NewsEditPage />,
  },
  {
    path: '/news',
    element: (
    <>
    <Navigation />
    <NewsHome />
    </>
    ),
  },
  {
    path: '/news/:news_id',
    element: (
    <>
    <Navigation />
    <NewsArticle />
    </>
    ),
  }
]);