import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './NewsArticle.css';


const NewsHome = () => {
  const { news_id } = useParams();

  const [htmlContent, setHtmlContent] = useState('');
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);

  // 從 GCS 獲取 HTML 內容
  useEffect(() => {
    const fetchHtmlContent = async () => {
      try {
        const response = await axios.get(
          `https://storage.googleapis.com/www.usdtprice.com/public/html/${news_id}`
        );
        setHtmlContent(response.data);
      } catch (error) {
        console.error('無法載入 HTML 內容:', error);
      }
    };
    
    fetchHtmlContent();
  }, [news_id]);

  // 從後端 API 獲取最新文章
  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await axios.get(`https://price-backend-765051711628.asia-east1.run.app/news/related/${news_id}`);
        // const response = await axios.get(`http://localhost:8080/news/related/${news_id}`);

        setArticles(response.data);
        setLoading(false);
      } catch (error) {
        console.error('無法載入最新文章:', error);
        setLoading(false);
      }
    };

    fetchArticles();
  }, [news_id]);

  if (loading) {
    return <div>載入中...</div>;
  }

  return (
    <div className="news-container">
      <div className="main-content"
        dangerouslySetInnerHTML={{ __html: htmlContent }}
      />

      
      {/* 最新文章列表 */}  
      <div className="sidebar">
        <h2>最新新聞</h2>
        <ul>
          {articles.map((article) => (
            <li key={article.ArticleID}>
              <a href={`/news/${article.ArticleID}`}>
                {article.title}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default NewsHome;