import React, { useState, useEffect, useRef, useCallback } from 'react';
import Quill from 'quill';
import { SnowTheme } from 'quill-color-picker-enhance';
import 'quill/dist/quill.snow.css';
import 'quill-color-picker-enhance/dist/index.css';
import './NewsEditPage.css';
import { v4 as uuidv4 } from 'uuid';
import imageCompression from 'browser-image-compression';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '';


// 註冊增強的 Snow 主題
Quill.register('themes/snow-quill-color-picker-enhance', SnowTheme);

// 註冊 ImageResize 模組
// Quill.register('modules/imageResize', ImageResize);

function NewsEditPage() {
  const [title, setTitle] = useState('');
  const [excerpt, setExcerpt] = useState(''); // 新增摘要狀態
  const [content, setContent] = useState('');
  const [images, setImages] = useState({});
  const editorRef = useRef(null);
  const quillRef = useRef(null);

  const compressImage = async (file) => {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 2000,
      useWebWorker: true
    };
    
    try {
      return await imageCompression(file, options);
    } catch (error) {
      console.error('圖片壓縮失敗:', error);
      throw error;
    }
  };
  
  const handleImageUpload = useCallback(async (file) => {
    try {
      const compressedFile = await compressImage(file);
      const reader = new FileReader();
      reader.readAsDataURL(compressedFile);
      
      return new Promise((resolve) => {
        reader.onload = (event) => {
          const imageId = uuidv4();
          const imageKey = `{{image:${imageId}}}`;
          const imageDataUrl = event.target.result;
          setImages(prevImages => ({
            ...prevImages,
            [imageKey]: imageDataUrl
          }));
          resolve({ imageKey, imageDataUrl });
        };
      });
    } catch (error) {
      console.error('圖片處理失敗:', error);
      throw error;
    }
  }, []);

  useEffect(() => {
    if (editorRef.current && !quillRef.current) {
      quillRef.current = new Quill(editorRef.current, {
        theme: 'snow-quill-color-picker-enhance',
        modules: {
          toolbar: [
            [{ 'header': [1, 2, false] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            ['blockquote', 'code-block'],
            ['link', 'image', 'video'],
            ['clean']
          ],
        },
        formats: ['header', 'bold', 'italic', 'underline', 'strike', 'list', 'bullet', 'blockquote', 'code-block', 'link', 'image', 'video', 'width', 'height'],
        placeholder: '在此輸入文章內容...',
      });

      quillRef.current.on('text-change', () => {
        setContent(quillRef.current.root.innerHTML);
      });

      // 添加圖片上傳處理
      quillRef.current.getModule('toolbar').addHandler('image', () => {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();

        input.onchange = async () => {
          const file = input.files[0];
          if (file) {
            try {
              const { imageKey, imageDataUrl } = await handleImageUpload(file);
              const range = quillRef.current.getSelection(true);
              
              // 插入圖片到編輯器，使用 imageDataUrl 顯示
              quillRef.current.insertEmbed(range.index, 'image', imageDataUrl);
              quillRef.current.setSelection(range.index + 1);

              // 獲取插入的圖片元素
              const imageElement = quillRef.current.root.querySelector(`img[src="${imageDataUrl}"]`);
              if (imageElement) {
                // 設置自定義屬性來存儲 imageKey
                imageElement.setAttribute('data-image-key', imageKey);
              }
            } catch (error) {
              console.error('圖片上傳失敗:', error);
              // 這裡可以添加一些用戶友好的錯誤提示
            }
          }
        };
      });
    }

    // 初始化內容（如果需要的話）
  }, [handleImageUpload]);

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleExcerptChange = (e) => { 
    setExcerpt(e.target.value);
  };

  const saveArticle = () => {
    console.log('保存文章', { title, excerpt, content });
  };

  const publishArticle = async () => {
    const currentDate = new Date().toISOString().split('T')[0];
    const htmlContent = `
      <!DOCTYPE html>
      <html lang="zh-TW">
      <head>
          <meta charset="UTF-8">
          <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>${title}</title>
      </head>
      <body>
          <h1 class="article-title">${title}</h1>
          <div class="publish-date">${currentDate}</div>
          <div class="excerpt">${excerpt}</div> 
          <div class="content">
            ${content}
          </div>
      </body>
      </html>
    `;

    // const blob = new Blob([htmlContent], { type: 'text/html' });
    // const url = URL.createObjectURL(blob);
    // const a = document.createElement('a');
    // a.href = url;
    // a.download = 'news.html';
    // document.body.appendChild(a);
    // a.click();
    // document.body.removeChild(a);
    // URL.revokeObjectURL(url);
      // 替換 HTML 中的圖片 URL 為 imageKey
      const processedHtml = htmlContent.replace(/<img[^>]+>/g, (match) => {
        const imageKeyMatch = match.match(/data-image-key="([^"]+)"/);
        if (imageKeyMatch) {
          return `<img src="${imageKeyMatch[1]}" />`;
        }
        return match;
      });
  
      const payload = {
        title,
        excerpt,
        html: processedHtml,
        images
      };
  
      try {
        const response = await fetch(`${API_BASE_URL}/api/articles/publish`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        });
  
        if (!response.ok) {
          throw new Error('文章發布失敗');
        }
  
        const result = await response.json();
        console.log('文章發布成功:', result);
        // 這裡可以添加發布成功後的處理邏輯
      } catch (error) {
        console.error('文章發布失敗:', error);
        // 這裡可以添加錯誤處理邏輯
      }
    };
  

  return (
    <div className="news-edit-body">
      <div className="article-edit-page">
        <h1 className="page-title">編輯文章</h1>
        <div className="editor-container">
          <input
            type="text"
            value={title}
            onChange={handleTitleChange}
            className="title-input"
            placeholder="請輸入文章標題"
          />
          <textarea
            value={excerpt}
            onChange={handleExcerptChange}
            className="excerpt-input"
            placeholder="請輸入文章摘要"
          />
          <div ref={editorRef} className="content-editor custom-quill"></div>
          <div className="button-group">
            <button type="button" className="editor-button submit-button" onClick={saveArticle}>保存文章</button>
            <button type="button" className="editor-button publish-button" onClick={publishArticle}>發布文章</button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default NewsEditPage;
